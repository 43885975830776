<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar :to="`/feriados`" />
        </v-col>
      </v-row>
    </v-card-actions>

    <feriados-campos
      :carregando-salvar="carregandoSalvar"
      :validacao-formulario="validacaoFormulario"
      :feriado="feriado"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import feriados from "@/api/feriados";

export default {
  components: {
    FeriadosCampos: () => import("./FeriadosCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
     
      validacaoFormulario: {},
      feriado: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await feriados.buscar(this.$route.params.id);
        var feriado = {
          descricao: resposta.data.data.descricao,
          ativo: resposta.data.data.ativo,
          fixo: resposta.data.data.fixo,
          dia_mes: resposta.data.data.dia_mes,
          ano: resposta.data.data.ano,
        };
        this.feriado = feriado;
      } catch (e) {
        this.$router.push(`/feriados`, () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(feriado) {
      this.carregandoSalvar = true;

      try {
        await feriados.atualizar(this.$route.params.id, feriado);
        this.$router.push(`/feriados`, () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
